import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Lightbox from "lightbox2";
import "lightbox2/dist/css/lightbox.min.css";
// import 'lightbox2/dist/js/lightbox.js';

export default function Portfolio() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    Lightbox.option({
      resizeDuration: 200,
      fadeDuration: 600,
      imageFadeDuration: 600,
      wrapAround: true,
    });
  }, []);
  return (
    <section
      className="portfolio-area page-section scroll-to-page"
      id="portfolio"
    >
      <div className="custom-container">
        <div className="portfolio-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-grip-vertical"></i> portfolio
            </h4>
            <h1 className="scroll-animation">
              Featured <span>Projects</span>
            </h1>
          </div>

          <div className="row portfolio-items">
            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/smart.png"
                    data-lightbox="example-1"
                  >
                    <img src="../assets/images/smart.png" alt="places-app" />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a>REACT</a>
                    </li>
                    <li>
                      <a>OPEN AI</a>
                    </li>
                    <li>
                      <a>NODE JS</a>
                    </li>
                    <li>
                      <a>GPT 3.5 TURBO MODEL</a>
                    </li>
                    <li>
                      <a>EXPRESS JS</a>
                    </li>
                    <li>
                      <a>MUI</a>
                    </li>
                    <li>
                      <a>FORMIK + YUP </a>
                    </li>
                    <li>
                      <a>...others</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://smart-plate-chef.web.app/" target="_blank">
                    SmartPlate Chef - Full Stack Application with AI
                  </a>
                </h2>
                <br />
                <h2>
                  <a
                    href="https://github.com/boydeveloper1/smartPlateChef"
                    target="_blank"
                  >
                    Git Repo
                  </a>
                </h2>
              </div>
            </div>
            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/eventH.png"
                    data-lightbox="example-1"
                  >
                    <img src="../assets/images/eventH.png" alt="places-app" />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a>STRIPE</a>
                    </li>
                    <li>
                      <a>CLOUDINARY</a>
                    </li>
                    <li>
                      <a>JSON WEB TOKEN</a>
                    </li>
                    <li>
                      <a>BCRYPT</a>
                    </li>
                    <li>
                      <a>EXPRESS VALIDATORS</a>
                    </li>
                    <li>
                      <a>MUI - MATERIAL UI</a>
                    </li>
                    <li>
                      <a>GOOGLE MAP API</a>
                    </li>
                    <li>
                      <a>...others</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://eventhiveweb100.web.app/" target="_blank">
                    EventHive - Mern Stack
                  </a>
                </h2>
                <br />
                <h2>
                  <a
                    href="https://github.com/boydeveloper1/Event-Hive"
                    target="_blank"
                  >
                    Git Repo
                  </a>
                </h2>
              </div>
            </div>
            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/places-app.png"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/places-app.png"
                      alt="places-app"
                    />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a>CRUD</a>
                    </li>
                    <li>
                      <a>MongoDb</a>
                    </li>
                    <li>
                      <a>NodeJs & ExpressJS</a>
                    </li>
                    <li>
                      <a>ReactJs</a>
                    </li>
                    <li>
                      <a>Authentication and Authorization</a>
                    </li>
                    <li>
                      <a>Client and Server side Validation</a>
                    </li>
                    <li>
                      <a>...others</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://mern-stack-frontend-b15b1.web.app/"
                    target="_blank"
                  >
                    Places App - MERN Stack
                  </a>
                </h2>
                <br />
                <h2>
                  <a
                    href="https://github.com/boydeveloper1/Your-Placess--Full-stack-App"
                    target="_blank"
                  >
                    Git Repo
                  </a>
                </h2>
              </div>
            </div>
            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-full">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/yelpcamp.png"
                    data-lightbox="example-1"
                  >
                    <img src="../assets/images/yelpcamp.png" alt="yelpcamp" />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a>Vanilla JavaScript</a>
                    </li>
                    <li>
                      <a>MongoDb</a>
                    </li>
                    <li>
                      <a>NodeJs</a>
                    </li>
                    <li>
                      <a>Ejs</a>
                    </li>
                    <li>
                      <a>RESTFUL</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://quiet-fortress-15769.herokuapp.com/"
                    target="_blank"
                  >
                    YelpCamp - Campground Website
                  </a>
                </h2>
                <br />
                <h2>
                  <a
                    href="https://github.com/boydeveloper1/yelp-camp"
                    target="_blank"
                  >
                    Git Repo
                  </a>
                </h2>
              </div>
            </div>

            {/* <div className="col-md-6 scroll-animation" data-aos="fade-right">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/portfolio2.jpg"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/portfolio2.jpg"
                      alt="Portfolio"
                    />
                  </a>

                  <img src="../assets/images/portfolio2.jpg" alt="Portfolio" />

                  <ul className="portfolio-categories">
                    <li>
                      <a href="https://www.wordpress.com">WordPress</a>
                    </li>
                    <li>
                      <a href="https://www.larevel.com">Larevel/PHP</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://www.moonex.com">Moonex WordPress Theme</a>
                </h2>
              </div>
            </div> */}

            {/* <div className="col-md-6 scroll-animation" data-aos="fade-left">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/portfolio3.jpg"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/portfolio3.jpg"
                      alt="Portfolio"
                    />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a href="https://www.figma.com">Figma</a>
                    </li>
                    <li>
                      <a href="https://www.webflow.com">Webflow</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a href="https://www.taskly-dashboard.com">
                    Taskly Dashboard
                  </a>
                </h2>
              </div>
            </div> */}

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/clothing-website.png"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/clothing-website.png"
                      alt="clothing-website"
                    />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a>ReactJs</a>
                    </li>
                    <li>
                      <a>Firebase</a>
                    </li>
                    <li>
                      <a>Typescript</a>
                    </li>
                    <li>
                      <a>RTL</a>
                    </li>
                    <li>
                      <a>GraphQL</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://roaring-shortbread-01b460.netlify.app/"
                    target="_blank"
                  >
                    Clothing Website - REACT
                  </a>
                </h2>
                <br />
                <h2>
                  <a
                    href="https://github.com/boydeveloper1/clothing-website"
                    target="_blank"
                  >
                    Git Repo
                  </a>
                </h2>
              </div>
            </div>

            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-half">
                <div className="portfolio-item-inner">
                  <a
                    href="../assets/images/monster.png"
                    data-lightbox="example-1"
                  >
                    <img
                      src="../assets/images/monster.png"
                      alt="clothing-website"
                    />
                  </a>

                  <ul className="portfolio-categories">
                    <li>
                      <a>ReactJs</a>
                    </li>

                    <li>
                      <a>Typescript</a>
                    </li>
                  </ul>
                </div>
                <h2>
                  <a
                    href="https://visionary-sawine-fef75b.netlify.app/"
                    target="_blank"
                  >
                    Monster Search App - REACT
                  </a>
                </h2>
                <br />
                <h2>
                  <a
                    href="https://github.com/boydeveloper1/monster-rolodex-app"
                    target="_blank"
                  >
                    Git Repo
                  </a>
                </h2>
              </div>
            </div>
            <div className="col-md-12 scroll-animation" data-aos="fade-up">
              <div className="portfolio-item portfolio-half">
                <h2>Here are a few examples from my Wordpress portfolio.</h2>
                <br />
                <h1 className="wordpress">
                  <a href="https://summerdigital.net/" target="_blank">
                    Summer Digital
                  </a>
                </h1>
                <h1 className="wordpress">
                  <a href="https://www.weaverlawyers.com/" target="_blank">
                    The Weaver Law Firm
                  </a>
                </h1>
                <h1 className="wordpress">
                  <a href="https://prmtdentertainment.com/" target="_blank">
                    Prmtd Entertainment
                  </a>
                </h1>
                <h1 className="wordpress">
                  <a href="https://prmtdentertainment.com/" target="_blank">
                    Prmtd Entertainment
                  </a>
                </h1>
                <h1 className="wordpress">
                  <a href="https://trends-studios.com/" target="_blank">
                    Trends Studios
                  </a>
                </h1>
                <h1 className="wordpress">
                  <a href="https://thealgarveexperience.com/" target="_blank">
                    The Algarve Experience
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
