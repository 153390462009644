import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Skills() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="skills-area page-section scroll-to-page" id="skills">
      <div className="custom-container">
        <div className="skills-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-shapes"></i> Technologies
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Some <span>Technologies</span> I use
            </h1>
          </div>

          <div className="row skills text-center">
            <div className="col-md-3 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/javascript.png" alt="Javascript" />
                  {/* <h1 className="percent">92%</h1> */}
                </div>
                <p className="name">Javascript</p>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/react.png" alt="React" />
                  {/* <h1 className="percent">React</h1> */}
                </div>
                <p className="name">React</p>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-down">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/typescript.png" alt="Webflow" />
                  {/* <h1 className="percent">80%</h1> */}
                </div>
                <p className="name">Typescript</p>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-left">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/nodejs.png" alt="Node-Js" />
                  {/* <h1 className="percent">90%</h1> */}
                </div>
                <p className="name">NodeJs</p>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/graphql.png" alt="GraphQl" />
                  {/* <h1 className="percent">86%</h1> */}
                </div>
                <p className="name">GraphQl</p>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/mongodb.png" alt="mongodb" />
                  {/* <h1 className="percent">70%</h1> */}
                </div>
                <p className="name">Mongodb</p>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/firebase.png" alt="firebase" />
                  {/* <h1 className="percent">70%</h1> */}
                </div>
                <p className="name">Firebase</p>
              </div>
            </div>
            <div className="col-md-3 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img src="../assets/images/bootstrap.png" alt="bootstrap" />
                  {/* <h1 className="percent">70%</h1> */}
                </div>
                <p className="name">Bootstrap</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
