import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Resume() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
      <div className="custom-container">
        <div className="resume-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-briefcase"></i> Resume
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              <span>Experience</span>
            </h1>
          </div>

          <div className="resume-timeline">
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">2023 - Present</span>
              <h2>Software Support Specialist</h2>
              <h5>@Intuit · Full-time</h5>
              <p>
                • Achieved a 95% customer satisfaction score by resolving over
                50 complex technical issues related to QuickBooks Online within
                an average resolution time of 24 hours.
              </p>
              <p>
                • Increased first-call resolution rate by 20% through the
                implementation of targeted troubleshooting procedures, reducing
                the need for follow-up calls and improving customer experience.
              </p>
              <p>
                • Assisted 200+ customers in successfully connecting their bank
                accounts for payroll direct deposits, leading to a 15% increase
                in payroll processing efficiency and reduced customer setup
                time.
              </p>
              <p>
                • Crafted thorough investigation notes for new software issues,
                showcasing expertise in product malfunction. Facilitated
                seamless collaboration with the engineering team for efficient
                issue resolution.
              </p>
              <p>
                • Trained and mentored 15 new support specialists, leading to a
                30% reduction in onboarding time and a 25% improvement in their
                first-month performance metrics.
              </p>
            </div>
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">2021 - 2022</span>
              <h2>Software Developer</h2>
              <h5>@Summer Digial Agency · Full-time</h5>
              <p>
                • Spearheaded frontend development initiatives for various
                projects, reducing project delivery time by 25% while
                maintaining high code quality.
              </p>
              <p>
                • Implemented CI/CD pipelines using Jenkins, automating the
                deployment process and reducing release times by 20%.
              </p>
              <p>
                • Utilized React and Material-UI to create visually appealing
                and user-friendly interfaces, boosting customer satisfaction
                scores by 18%.
              </p>
              <p>
                • Collaborated closely with design and content teams to revamp
                websites, increasing website traffic by 40% and conversion rates
                by 25%.
              </p>
              <p>
                • Collaborated with clients to gather requirements, define
                project scope, and establish project timelines and milestones,
                fostering strong client relationships and satisfaction.
              </p>
              <p>
                • Achieved high code coverage (90%) through the implementation
                of comprehensive unit tests and integration tests, ensuring
                robustness and reliability of applications.
              </p>
            </div>
            <div className="item scroll-animation" data-aos="fade-left">
              <span className="date">2018 - 2021</span>
              <h2>Full Stack Software Developer</h2>
              <h5>@Fiverr · Freelance</h5>
              <p>
                • Developed tailored solutions for diverse global clients,
                ranging from one-page websites to complex multi-page
                applications, meeting and exceeding client Key Performance
                Indicators (KPIs).
              </p>
              <p>
                • Maximized user experience, driving 25% higher online
                conversions with WordPress and HTML/CSS/JavaScript.
              </p>
              <p>
                • Achieved level two badge status on Fiverr, attesting to a
                consistent track record of client satisfaction through the
                successful delivery of 60+ high-quality gigs.
              </p>
              <p>
                • Leveraged industry best practices in security and performance
                optimization to protect client data and improve application
                speed and efficiency, resulting in enhanced user satisfaction
                and retention.
              </p>
              <p>
                • Managed multiple projects simultaneously, prioritizing tasks
                and allocating resources effectively to meet project milestones
                and deliverables.
              </p>
              <p>
                • Actively participated in the Fiverr community, sharing
                knowledge and expertise with fellow freelancers, contributing to
                discussions, and providing assistance to newcomers to foster a
                supportive and collaborative environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
