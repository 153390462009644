import React from "react";
import Pdf from "../../Pdf/Adetayo-Chukwudi-Adebayo.pdf";

const Lsb = () => {
  return (
    <div className="left-sidebar">
      <div className="sidebar-header d-flex align-items-center justify-content-between">
        <img src="./assets/images/logo.png" alt="Adetayo Chukwudi" />
        <span className="designation">Software Engineer</span>
      </div>
      <img
        className="me"
        src="./assets/images/adetayo-chukwudi.jpg"
        alt="adetayo-chukwudi"
      />
      <h2 className="email">applications@adetayo.net</h2>
      <h2 className="address">Ontario, Canada</h2>
      <p className="copyright">
        &copy; 2024 Design and Developed. Adetayo Adebayo
      </p>
      <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
        <li>
          <a href="https://www.linkedin.com/in/adetayo-chukwudi-adebayo-27a8491ba/">
            <i className="lab la-linkedin"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/teeyy_yyy">
            <i className="lab la-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/boy_developer/">
            <i className="lab la-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://github.com/boydeveloper1">
            <i className="lab la-github"></i>
          </a>
        </li>
      </ul>
      <a href={Pdf} className="theme-btn-resume">
        <i className="las la-link"></i> RESUME
      </a>
      <a href={"mailto:help@adetayo.net"} className="theme-btn" label="Resunme">
        <i className="las la-envelope"></i> Email me
      </a>
    </div>
  );
};

export default Lsb;
