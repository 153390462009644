import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function About() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="about-area page-section scroll-to-page" id="about">
      <div className="custom-container">
        <div className="about-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="lar la-user"></i> About
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Closer Look at My Background
              <br />
              <span> - My Journey So Far</span>
            </h1>
          </div>
          <p className="scroll-animation about-me-last" data-aos="fade-up">
            Since 2016, my journey as a self-taught software engineer has been a
            thrilling ride. It all began with a humble dive into web design,
            where I quickly found my groove and began offering professional
            services. This journey reached a significant milestone when I earned
            a <span>level 2 badge</span> on Fiverr, a testament to my dedication
            and growing expertise. But it was during this time that I truly
            discovered my passion for software engineering, fueled by the
            endless possibilities and challenges it presents.
          </p>
          <p className="scroll-animation about-me-last" data-aos="fade-up">
            What truly excites me is the prospect of collaborating with
            exceptional teams on projects that make a real impact. I thrive in
            environments where creativity and innovation are encouraged, and
            where every challenge is an opportunity for growth. My commitment
            lies in crafting software solutions that are not just
            well-structured, but also address real-world problems effectively.
          </p>
          <p className="scroll-animation about-me-last" data-aos="fade-up">
            While my journey started with web design, I'm eager to explore and
            embrace a wider range of technologies to deliver even more
            comprehensive solutions. The potential for growth and innovation in
            this field is limitless, and I'm excited to be a part of it. I look
            forward to the opportunities ahead and the chance to contribute
            meaningfully to the world of software engineering.
          </p>
        </div>
      </div>
    </section>
  );
}
